.login-container {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(135deg, #f0f7ff 0%, #ffffff 100%);
  position: relative;
  overflow: hidden;
  padding: 40px;
}

.login-background {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: radial-gradient(circle at 50% 50%, rgba(22, 119, 255, 0.05) 0%, rgba(255, 255, 255, 0) 50%);
}

.circles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.circle {
  position: absolute;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: rgba(22, 119, 255, 0.1);
  animation: animate 25s linear infinite;
  bottom: -150px;
  border-radius: 50%;
}

@keyframes animate {
  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
  }
  100% {
    transform: translateY(-1000px) rotate(720deg);
    opacity: 0;
  }
}

.login-card {
  width: 480px;
  padding: 48px !important;
  background: rgba(255, 255, 255, 0.95) !important;
  backdrop-filter: blur(20px);
  border-radius: 24px !important;
  box-shadow: 
    0 20px 40px rgba(0, 0, 0, 0.08),
    0 0 0 1px rgba(255, 255, 255, 0.8) !important;
  transform: translateY(0);
  transition: all 0.3s ease;
}

.login-card:hover {
  transform: translateY(-5px);
  box-shadow: 
    0 30px 60px rgba(0, 0, 0, 0.12),
    0 0 0 1px rgba(255, 255, 255, 0.8) !important;
}

.login-header {
  text-align: center;
  margin-bottom: 32px;
}

.logo-container {
  margin-bottom: 32px;
}

.logo-circle {
  width: 96px;
  height: 96px;
  margin: 0 auto;
  background: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 
    0 12px 24px rgba(24, 144, 255, 0.15),
    0 0 0 1px rgba(24, 144, 255, 0.1);
  animation: float 6s ease-in-out infinite;
  position: relative;
}

@keyframes float {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
}

.logo-circle::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(135deg, rgba(255,255,255,0.2), transparent);
  border-radius: 50%;
}

.logo-image {
  width: 70px;
  height: 70px;
  object-fit: contain;
  filter: drop-shadow(0px 4px 8px rgba(24, 144, 255, 0.2));
}

.login-header h1 {
  font-size: 32px;
  color: #1a1a1a;
  margin: 24px 0 12px;
  font-weight: 600;
  background: linear-gradient(135deg, #40A9FF, #1890FF);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.subtitle {
  color: #666;
  font-size: 16px;
  margin-bottom: 32px;
}

.login-form {
  margin-top: 32px;
}

.login-input {
  height: 52px !important;
  border-radius: 12px !important;
  font-size: 16px !important;
}

.login-input:hover, .login-input:focus {
  border-color: #1890FF !important;
  background: rgba(255, 255, 255, 0.95) !important;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.1) !important;
}

.input-icon {
  color: #bfbfbf;
}

.login-button {
  height: 52px !important;
  border-radius: 12px !important;
  font-size: 18px !important;
  margin-top: 24px !important;
  background: linear-gradient(135deg, #40A9FF, #1890FF) !important;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}

.login-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 8px 16px rgba(22, 119, 255, 0.3) !important;
}

/* 自定义复选框样式 */
.ant-checkbox-wrapper {
  font-size: 15px;
  color: #666;
}

.ant-checkbox-inner {
  width: 18px !important;
  height: 18px !important;
  border-radius: 4px !important;
}

/* 响应式设计优化 */
@media (max-width: 576px) {
  .login-card {
    width: 92%;
    margin: 24px;
    padding: 36px 24px !important;
  }
  
  .logo-circle {
    width: 80px;
    height: 80px;
  }
  
  .logo-image {
    width: 50px;
    height: 50px;
  }
  
  .login-header h1 {
    font-size: 28px;
  }
}

/* 装饰性背景元素 */
.decoration-container {
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.decoration-item {
  position: absolute;
  border-radius: 50%;
  background: linear-gradient(135deg, rgba(64, 169, 255, 0.1), rgba(24, 144, 255, 0.05));
}

.top-left {
  width: 300px;
  height: 300px;
  top: -150px;
  left: -150px;
}

.top-right {
  width: 200px;
  height: 200px;
  top: 100px;
  right: -100px;
}

.bottom-left {
  width: 250px;
  height: 250px;
  bottom: -100px;
  left: 100px;
}

.bottom-right {
  width: 350px;
  height: 350px;
  bottom: -175px;
  right: -175px;
}

.login-content {
  display: flex;
  align-items: center;
  gap: 80px;
  max-width: 1200px;
  width: 100%;
  position: relative;
  z-index: 1;
}

.login-left {
  flex: 1;
  padding: 40px;
  display: none; /* 默认隐藏，在大屏幕显示 */
}

.welcome-text {
  font-size: 24px;
  color: #666;
  margin-bottom: 12px;
}

.platform-name {
  font-size: 48px;
  font-weight: bold;
  background: linear-gradient(135deg, #40A9FF, #1890FF);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 24px;
}

.platform-desc {
  font-size: 18px;
  color: #666;
  margin-bottom: 48px;
}

.feature-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin-top: 32px;
}

.feature-item {
  text-align: center;
  padding: 24px 16px;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
}

.feature-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 24px rgba(24, 144, 255, 0.15);
}

.feature-item i {
  font-size: 28px;
  color: #1890FF;
  margin-bottom: 12px;
  display: block;
}

.feature-item span {
  font-size: 15px;
  color: #333;
  font-weight: 500;
}

/* 响应式设计 */
@media (min-width: 1200px) {
  .login-left {
    display: block;
  }
}

@media (max-width: 1199px) {
  .login-content {
    justify-content: center;
  }
}

@media (max-width: 576px) {
  .login-container {
    padding: 20px;
  }
  
  .login-card {
    width: 100%;
    padding: 32px 24px !important;
  }
}

/* 添加一些动画效果 */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.login-left {
  animation: fadeIn 1s ease-out;
}

.login-card {
  animation: fadeIn 1s ease-out 0.3s backwards;
}

/* 优化已有的表单元素样式 */
.login-input {
  backdrop-filter: blur(4px);
}

.login-button {
  margin-top: 32px !important;
}

/* 添加波浪动画背景 */
.wave {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100px;
  background: url('data:image/svg+xml,...') repeat-x;
  animation: wave 20s linear infinite;
}

@keyframes wave {
  0% {
    background-position-x: 0;
  }
  100% {
    background-position-x: 1000px;
  }
}