.history-list {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 16px;
  padding: 16px;
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
}

.history-card {
  background: white;
  border-radius: 8px;
  padding: 12px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
  transition: all 0.2s;
}

.history-card:hover {
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
}

.card-header {
  margin-bottom: 8px;
}

.folder-name {
  font-size: 14px;
  font-weight: 500;
  color: #2d3748;
  margin-bottom: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card-subheader {
  display: flex;
  flex-direction: column;
  gap: 4px;
  color: #718096;
  font-size: 12px;
}

.file-stats {
  display: flex;
  gap: 8px;
}

.stat-item {
  display: flex;
  align-items: center;
  gap: 3px;
}

.stat-item i {
  font-size: 12px;
}

.qr-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 0;
}

.qr-code {
  width: 100px;
  height: 100px;
  padding: 4px;
  background: white;
  border: 1px solid #e2e8f0;
  border-radius: 4px;
}

.card-actions {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 8px;
  padding-top: 12px;
  border-top: 1px solid #edf2f7;
  margin-top: auto;
}

.action-btn {
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  border: none;
  background: #f8fafc;
  color: #475569;
  cursor: pointer;
  border-radius: 6px;
  transition: all 0.2s ease;
  font-size: 13px;
  font-weight: 500;
  padding: 0 12px;
  position: relative;
  overflow: hidden;
}

/* 复制按钮 */
.action-btn.copy-btn {
  background: #e8f5e9;
  color: #2e7d32;
}

.action-btn.copy-btn:hover {
  background: #c8e6c9;
}

/* 查看按钮 */
.action-btn.view-btn {
  background: #e3f2fd;
  color: #1976d2;
}

.action-btn.view-btn:hover {
  background: #bbdefb;
}

/* 删除按钮 */
.action-btn.delete-btn {
  background: #fef2f2;
  color: #dc2626;
}

.action-btn.delete-btn:hover {
  background: #fee2e2;
}

/* 按钮图标样式 */
.action-btn i {
  font-size: 14px;
  transition: transform 0.2s ease;
}

/* 按钮悬停效果 */
.action-btn:hover {
  transform: translateY(-1px);
}

.action-btn:hover i {
  transform: scale(1.1);
}

/* 按钮点击效果 */
.action-btn:active {
  transform: translateY(1px);
}

/* 复制成功状态 */
.action-btn.copy-btn.copied {
  background: #4caf50;
  color: white;
}

.action-btn.copy-btn.copied i {
  animation: checkmark 0.3s ease-in-out;
}

@keyframes checkmark {
  0% { transform: scale(0); }
  50% { transform: scale(1.2); }
  100% { transform: scale(1); }
}

/* 响应式布局 */
@media (max-width: 1200px) {
  .history-list {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (max-width: 992px) {
  .history-list {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 768px) {
  .history-list {
    grid-template-columns: repeat(2, 1fr);
    gap: 8px;
    padding: 8px;
  }

  .history-card {
    padding: 8px;
  }

  .qr-code {
    width: 80px;
    height: 80px;
  }

  .card-actions {
    gap: 6px;
    padding-top: 10px;
  }

  .action-btn {
    height: 28px;
    padding: 0;
    width: 32px;
  }

  .action-btn i {
    font-size: 12px;
    margin: 0;
  }

  /* 保持颜色样式 */
  .action-btn.copy-btn,
  .action-btn.view-btn,
  .action-btn.delete-btn {
    border-radius: 4px;
  }

  .hide-on-mobile {
    display: none;
  }

  .action-btn {
    padding: 0;
    width: 28px;
  }
}

@media (max-width: 480px) {
  .history-list {
    grid-template-columns: repeat(1, 1fr);
  }
}

/* 历史记录头部样式 */
.history-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding: 0 16px;
}

.history-title {
  font-size: 18px;
  font-weight: 600;
  color: #1a202c;
}

.history-actions {
  display: flex;
  gap: 12px;
}

/* 顶部操作按钮基础样式 */
.header-btn {
  height: 36px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 0 20px;
  border: none;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

/* 导出按钮样式 - 使用渐变色 */
.export-btn {
  background: linear-gradient(135deg, #4facfe 0%, #00f2fe 100%);
  color: white;
}

.export-btn:hover {
  background: linear-gradient(135deg, #4facfe 20%, #00f2fe 120%);
  transform: translateY(-1px);
  box-shadow: 0 4px 8px rgba(79, 172, 254, 0.3);
}

.export-btn:active {
  transform: translateY(1px);
  box-shadow: 0 2px 4px rgba(79, 172, 254, 0.3);
}

.export-btn i {
  font-size: 14px;
  transition: transform 0.3s ease;
}

.export-btn:hover i {
  transform: translateX(-2px) rotate(-10deg);
}

/* 清空按钮样式 - 使用渐变色 */
.clear-btn {
  background: linear-gradient(135deg, #ff6b6b 0%, #ff4f4f 100%);
  color: white;
}

.clear-btn:hover {
  background: linear-gradient(135deg, #ff6b6b 20%, #ff4f4f 120%);
  transform: translateY(-1px);
  box-shadow: 0 4px 8px rgba(255, 107, 107, 0.3);
}

.clear-btn:active {
  transform: translateY(1px);
  box-shadow: 0 2px 4px rgba(255, 107, 107, 0.3);
}

.clear-btn i {
  font-size: 14px;
  transition: transform 0.3s ease;
}

.clear-btn:hover i {
  transform: rotate(15deg);
}

/* 按钮禁用状态 */
.header-btn:disabled {
  opacity: 0.7;
  cursor: not-allowed;
  transform: none !important;
  box-shadow: none !important;
}

/* 加载状态 */
.header-btn.loading {
  position: relative;
  color: transparent !important;
}

.header-btn.loading::after {
  content: '';
  position: absolute;
  width: 16px;
  height: 16px;
  border: 2px solid rgba(255, 255, 255, 0.8);
  border-radius: 50%;
  border-right-color: transparent;
  animation: spin 0.8s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* 响应式调整 */
@media (max-width: 768px) {
  .history-header {
    flex-direction: column;
    gap: 12px;
    align-items: flex-start;
    padding: 0 8px;
    margin-bottom: 16px;
  }

  .history-actions {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8px;
  }

  .header-btn {
    width: 100%;
    height: 32px;
    padding: 0 12px;
    font-size: 13px;
  }
}

/* 确认对话框样式 */
.confirm-dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 24px;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  z-index: 1000;
}

.confirm-dialog-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.empty-history {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-height: 200px;
  color: #999;
}

.empty-history i {
  font-size: 48px;
  margin-bottom: 16px;
}

.empty-history p {
  margin: 0;
  font-size: 16px;
}