.export-dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.export-dialog {
  background: white;
  border-radius: 12px;
  padding: 24px;
  width: 90%;
  max-width: 400px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.export-dialog h3 {
  margin: 0 0 16px 0;
  color: #333;
}

.export-option {
  margin-bottom: 24px;
}

.export-option label {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}

.export-option input[type="checkbox"] {
  width: 18px;
  height: 18px;
  cursor: pointer;
}

.export-dialog-buttons {
  display: flex;
  gap: 12px;
  justify-content: flex-end;
}

.export-btn {
  padding: 8px 16px;
  background: #2196F3;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background 0.2s ease;
}

.export-btn:hover {
  background: #1976D2;
}

.cancel-btn {
  padding: 8px 16px;
  background: #f5f5f5;
  color: #333;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background 0.2s ease;
}

.cancel-btn:hover {
  background: #e0e0e0;
} 