.upload-queue {
  margin-top: 20px;
  background: white;
  border-radius: 12px;
  padding: 16px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
}

.upload-queue h3 {
  margin: 0 0 16px 0;
  color: #333;
  font-size: 1.1em;
}

.queue-item {
  padding: 12px;
  margin-bottom: 12px;
  border-radius: 8px;
  background: #f5f5f5;
  transition: all 0.3s ease;
}

.queue-item:last-child {
  margin-bottom: 0;
}

.queue-item.uploading {
  background: #E3F2FD;
}

.queue-item.completed {
  background: #E8F5E9;
}

.queue-item.error {
  background: #FFEBEE;
}

.queue-item-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.folder-name {
  font-weight: 500;
  color: #333;
}

.status-text {
  font-size: 0.9em;
  color: #666;
}

.progress-bar {
  height: 4px;
  background: #BBDEFB;
  border-radius: 2px;
  overflow: hidden;
}

.progress {
  height: 100%;
  background: #2196F3;
  transition: width 0.3s ease;
} 