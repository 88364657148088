.existing-upload {
  color: #ff9800;
  font-weight: bold;
  margin-bottom: 10px;
}

.sub-text {
  font-size: 0.8em;
  color: #666;
  margin-top: 8px;
}

.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.upload-area {
  border: 2px dashed #ccc;
  border-radius: 12px;
  padding: 40px;
  text-align: center;
  background: white;
  transition: all 0.3s ease;
  cursor: pointer;
}

.upload-area.dragging {
  border-color: #2196F3;
  background: #E3F2FD;
}

.upload-content {
  text-align: center;
}

.upload-icon {
  font-size: 48px;
  color: #666;
  margin-bottom: 16px;
}

.upload-text {
  font-size: 1.2em;
  color: #333;
  margin-bottom: 8px;
}

.sub-text {
  color: #666;
  font-size: 0.9em;
  line-height: 1.6;
}

.status {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.spinner {
  width: 40px;
  height: 40px;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #2196F3;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.result-panel {
  margin-top: 20px;
  padding: 20px;
  background: white;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
}

.status-message {
  font-size: 1.2em;
  margin-bottom: 16px;
  padding: 8px;
  border-radius: 6px;
  text-align: center;
}

.status-message.success {
  background: #E8F5E9;
  color: #2E7D32;
}

.status-message.existing {
  background: #FFF3E0;
  color: #E65100;
}

.download-link {
  background: #f5f5f5;
  padding: 12px;
  border-radius: 6px;
  margin-bottom: 16px;
}

.download-link p {
  margin-bottom: 10px;
  color: #333;
}

.download-link a {
  color: #2196F3;
  text-decoration: none;
  word-break: break-all;
  font-size: 16px;
}

.download-link a:hover {
  text-decoration: underline;
}

.new-upload-btn {
  width: 100%;
  padding: 12px;
  background: #2196F3;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background 0.2s ease;
}

.new-upload-btn:hover {
  background: #1976D2;
}

/* 响应式设计 */
@media (max-width: 768px) {
  .container {
    padding: 10px;
  }

  .upload-area {
    padding: 20px;
  }

  .upload-icon {
    font-size: 36px;
  }

  .upload-text {
    font-size: 1.1em;
  }
}

.history-panel {
  background: white;
  border-radius: 8px;
  padding: 20px;
  margin-top: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.history-panel h2 {
  margin: 0 0 20px 0;
  color: #333;
  font-size: 1.5em;
}

.history-list {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.history-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  background: #f8f9fa;
  border-radius: 6px;
  transition: background-color 0.3s ease;
  margin-bottom: 10px;
}

.history-item:hover {
  background: #f0f0f0;
}

.history-info {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.history-date {
  color: #666;
  font-size: 0.9em;
}

.history-files {
  color: #888;
  font-size: 0.8em;
}

.history-link {
  color: #2196f3;
  text-decoration: none;
  padding: 6px 12px;
  border-radius: 4px;
  background: rgba(33, 150, 243, 0.1);
  transition: background-color 0.3s ease;
}

.history-link:hover {
  background: rgba(33, 150, 243, 0.2);
}

.loading {
  text-align: center;
  color: #666;
  padding: 20px;
}

.no-history {
  text-align: center;
  color: #666;
  padding: 20px;
  background: #f8f9fa;
  border-radius: 6px;
}

.history-actions {
  display: flex;
  gap: 10px;
}

.export-btn,
.clear-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  padding: 6px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: all 0.3s ease;
  min-width: 100px;
  height: 32px;
  white-space: nowrap;
}

.export-btn {
  background-color: #4CAF50;
  color: white;
}

.clear-btn {
  background-color: #f44336;
  color: white;
}

.export-btn:hover {
  background-color: #45a049;
}

.clear-btn:hover {
  background-color: #da190b;
}

.export-btn i,
.clear-btn i {
  font-size: 14px;
}

/* 禁用状态 */
.export-btn:disabled,
.clear-btn:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.delete-btn {
  background: #ff4444;
  color: white;
  border: none;
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.delete-btn:hover {
  background: #ff1744;
}

.delete-btn:disabled {
  background: #ffcdd2;
  cursor: not-allowed;
}

@media (max-width: 480px) {
  .history-item {
    flex-direction: column;
    gap: 10px;
    text-align: center;
  }

  .history-actions {
    width: 100%;
    justify-content: center;
  }
}

.history-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.history-header h2 {
  margin: 0;
  color: #333;
}

.export-btn {
  padding: 8px 16px;
  background: #4CAF50;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background 0.2s ease;
  color: white;
}

.export-btn:hover {
  background: #e0e0e0;
}

.export-btn:active {
  transform: scale(0.98);
}


@media (max-width: 480px) {
  .history-header {
    flex-direction: column;
    gap: 10px;
    text-align: center;
  }

  .export-btn {
    width: 100%;
    justify-content: center;
  }
}

.history-folder {
  font-weight: 500;
  color: #333;
  margin-bottom: 4px;
}

.export-dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.export-dialog {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 90%;
  max-width: 400px;
}

.export-dialog h3 {
  margin: 0 0 20px 0;
  color: #333;
}

.export-option {
  margin-bottom: 20px;
}

.export-option label {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}

.export-option input[type="checkbox"] {
  width: 18px;
  height: 18px;
}

.export-dialog-buttons {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
}

.cancel-btn {
  background: #f5f5f5;
  color: #666;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.cancel-btn:hover {
  background: #e0e0e0;
}

@media (max-width: 480px) {
  .history-folder {
    font-size: 16px;
  }

  .export-dialog {
    width: calc(100% - 40px);
    margin: 20px;
  }
}

.copy-btn {
  background: #2196F3;
  color: white;
  border: none;
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 4px;
}

.copy-btn:hover {
  background: #1976D2;
}

.copy-btn:active {
  transform: scale(0.98);
}

.copy-btn.copied {
  background: #4CAF50;
}

.copy-btn.copied:before {
  content: "✓";
  font-size: 14px;
}

.history-actions {
  display: flex;
  gap: 8px;
  align-items: center;
}

/* 移动端适配 */
@media (max-width: 480px) {
  .history-item {
    flex-direction: column;
    gap: 12px;
  }

  .history-actions {
    width: 100%;
    justify-content: center;
  }

  .copy-btn, .history-link, .delete-btn {
    flex: 1;
    text-align: center;
    justify-content: center;
  }
}

/* 添加按钮禁用状态样式 */
.copy-btn:disabled {
  background: #ccc;
  cursor: not-allowed;
}

/* 添加复制成功动画 */
@keyframes copySuccess {
  0% { transform: scale(1); }
  50% { transform: scale(1.1); }
  100% { transform: scale(1); }
}

.copy-btn.copied {
  animation: copySuccess 0.3s ease;
}

.split-layout {
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
  background: #f0f2f5;
}

.upload-section {
  width: 400px;
  padding: 24px;
  background: white;
  box-shadow: 1px 0 8px rgba(0, 0, 0, 0.05);
  overflow-y: auto;
  height: 100%;
  border-right: 1px solid rgba(0, 0, 0, 0.06);
}

.history-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: 100%;
  padding: 24px;
}

.history-header {
  padding: 0 0 16px 0;
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: transparent;
  position: relative;
}

.history-header h2 {
  font-size: 20px;
  color: #1a1a1a;
  margin: 0;
  display: flex;
  align-items: center;
  gap: 8px;
}

.history-actions {
  display: flex;
  gap: 12px;
}

.history-actions button {
  padding: 8px 16px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  transition: all 0.3s ease;
  background: white;
  color: #1677ff;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02);
}

.export-btn {
  border: 1px solid #1677ff !important;
}

.export-btn:hover {
  background: #1677ff !important;
  color: white !important;
}

.clear-btn {
  border: 1px solid #ff4d4f !important;
  color: #ff4d4f !important;
}

.clear-btn:hover {
  background: #ff4d4f !important;
  color: white !important;
}

.history-content {
  flex: 1;
  overflow-y: auto;
  padding-right: 8px;
  position: relative;
}

/* 美化滚动条 */
.history-content::-webkit-scrollbar {
  width: 6px;
}

.history-content::-webkit-scrollbar-track {
  background: #f0f2f5;
  border-radius: 3px;
}

.history-content::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 3px;
}

.history-content::-webkit-scrollbar-thumb:hover {
  background: #999;
}

/* 添加卡片动画效果 */
.history-item {
  transition: all 0.3s ease;
  border-radius: 8px;
  border: 1px solid #f0f0f0;
  margin-bottom: 12px;
  background: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.04);
}

.history-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
}

/* 加载动画 */
.spinner {
  width: 40px;
  height: 40px;
  border: 3px solid #f0f0f0;
  border-top: 3px solid #1677ff;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.page-title {
  font-size: 24px;
  color: #1a1a1a;
  font-weight: 600;
  margin: 0 0 24px 0;
  position: relative;
  padding-left: 12px;
}

.page-title::before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 4px;
  height: 20px;
  background: linear-gradient(45deg, #1677ff, #4096ff);
  border-radius: 2px;
}

.upload-area {
  border: 2px dashed #d9d9d9;
  border-radius: 12px;
  padding: 32px;
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(145deg, #ffffff, #f9fafb);
  transition: all 0.3s ease;
  margin-bottom: 24px;
  position: relative;
  overflow: hidden;
}

.upload-area:hover {
  border-color: #1677ff;
  background: linear-gradient(145deg, #f0f7ff, #ffffff);
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}

.upload-area.dragging {
  border-color: #1677ff;
  background: #f0f7ff;
  box-shadow: 0 0 0 2px rgba(22, 119, 255, 0.2);
}

.upload-icon {
  font-size: 48px;
  color: #1677ff;
  margin-bottom: 16px;
  animation: float 3s ease-in-out infinite;
}

@keyframes float {
  0% { transform: translateY(0px); }
  50% { transform: translateY(-10px); }
  100% { transform: translateY(0px); }
}

.upload-content {
  text-align: center;
  padding: 2rem;
}

.upload-icon {
  font-size: 3rem;
  color: #8c9bab;
  margin-bottom: 1rem;
}

.upload-text {
  font-size: 1.2rem;
  color: #2c3e50;
  margin-bottom: 1rem;
}

.sub-text {
  color: #8c9bab;
  line-height: 1.6;
}

/* 历史记录样式 */
.history-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

.history-header h2 {
  font-size: 1.5rem;
  color: #2c3e50;
  font-weight: 600;
  margin: 0 0 1.5rem 0;
}

.history-actions {
  display: flex;
  gap: 1rem;
}

.history-actions button {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 6px;
  background: white;
  color: #4a5568;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  transition: all 0.2s ease;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
}

.history-actions button:hover {
  background: #f8fafc;
  color: #2d3748;
}

.history-actions i {
  font-size: 0.9rem;
}

/* 二维码样式 */
.qr-code {
  padding: 1.5rem;
  background: white;
  border-radius: 12px;
  text-align: center;
  box-shadow: 0 2px 8px rgba(0,0,0,0.05);
}

.qr-code img {
  width: 180px;
  height: 180px;
  margin-bottom: 1rem;
}

.qr-code-text {
  color: #4a5568;
  font-size: 0.95rem;
}

/* 标题样式 */
.page-title {
  font-size: 1.5rem;
  color: #2d3748;
  font-weight: 600;
  margin: 0 0 1.5rem 0;
}

/* 响应式调整 */
@media (max-width: 1024px) {
  .split-layout {
    flex-direction: column;
  }
  
  .upload-section {
    width: 100%;
    height: auto;
    border-right: none;
    border-bottom: 1px solid #eaeef5;
  }

  .history-section {
    height: auto;
  }
}

/* 历史记录网格布局 */
.history-list {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 16px;
  width: 100%;
}

.history-card {
  background: white;
  border-radius: 8px;
  padding: 12px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
}

/* 响应式布局 */
@media (max-width: 1600px) {
  .history-list {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (max-width: 1400px) {
  .history-list {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 1200px) {
  .history-list {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .split-layout {
    flex-direction: column;
  }

  .upload-section {
    width: 100%;
    height: auto;
    border-right: none;
    border-bottom: 1px solid #eaeef5;
  }

  .history-list {
    grid-template-columns: repeat(2, 1fr);
  }
}